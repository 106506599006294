<template>
  <NavCheckAndConfirm :text="trusteesDescription" :backTo="backTo">
    <template></template>
  </NavCheckAndConfirm>
</template>

<script>
import NavCheckAndConfirm from '@/common/ui/NavCheckAndConfirm'
import { willStringHelpers } from '@/common/mixins/willStringHelpers'
import { sectionDescriptions } from '@/common/mixins/sectionDescriptions'

export default {
  name: 'TrusteesCheckAndConfirm',
  mixins: [willStringHelpers, sectionDescriptions],
  components: {
    NavCheckAndConfirm
  },
  computed: {
    details() {
      return this.$store.getters.details
    },
    partner() {
      return this.$store.getters.partner
    },
    backTo() {
      if (this.details.same_as_executors)
        return '/trustees/executors_as_trustees'
      else if (!this.partner && this.details.trustees_secondary_required)
        return '/trustees/secondary'
      else if (!this.partner && !this.details.trustees_secondary_required)
        return '/trustees/secondary_option'
      else if (this.partner && this.details.trustees_backup_required)
        return '/trustees/backup'
      else if (
        !this.details.trustees_backup_required &&
        this.partner &&
        this.partner.trustee_sole_primary
      )
        return '/trustees/backup_option'
      return '/trustees/secondary'
    }
  },
  methods: {},
  beforeRouteLeave(to, from, next) {
    this.$store.dispatch('savePeople')
    this.$store.dispatch('saveDetails')
    this.$notification.success('Trustees Saved')
    next()
  }
}
</script>

<style></style>
